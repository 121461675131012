<template>
    <div class="d-flex">
        <Number 
            v-if="include_input"
            v-model="quantity"
            customClass="quantity_input me-2"
            :field="{slug: 'quantity', placeholder: 'quantity'}"/>
        <button class="btn btn-warning text-white text-nowrap w-100" @click.prevent="addToCart(course_slug, quantity)" :disabled="addToCartLoading">
            {{text}}
            <div v-if="addToCartLoading" class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only"></span>
            </div>
        </button>
    </div>
</template>
<script>
import Number from '/components/pub/helpers/fields/Number.vue';
import cart from '/components/pub/mixins/cart.js';
export default {
    props: {
        include_input: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: 'Buy Seat'
        },
        course_slug: {
            type: String,
            required: true
        }
    },
    mixins: [cart],
    components: { Number },
    data: () => ({
        quantity: 1
    }),
    watch: {
        quantity: {
            handler: function (newValue) {
                if(newValue <= 0){
                    this.quantity = 1; 
                }
            },
            immediate: true
        }
    },
}
</script>
<style>
.quantity_input {
    display: block;
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.spinner-border-sm{
    width: 1rem !important;
}
</style>