
<div class="d-flex">
    <Number 
        v-if="include_input"
        v-model="quantity"
        customClass="quantity_input me-2"
        :field="{slug: 'quantity', placeholder: 'quantity'}"/>
    <button class="btn btn-warning text-white text-nowrap w-100" @click.prevent="addToCart(course_slug, quantity)" :disabled="addToCartLoading">
        {{text}}
        <div v-if="addToCartLoading" class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only"></span>
        </div>
    </button>
</div>
